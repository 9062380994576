.clear-button {
  margin-left: 1em;
  background-color: #db3e30;
  color: #fff;
  padding: 0.8em;
  cursor: pointer;
  font-size: 1.5em;
  border: none;
  border-radius: 2em;
  box-shadow: 1px 1px 8px 2px #95a5a6;
}
h1{
  text-align: center;
  color: #fff;
  margin-top: 30%;
}
.video {
  object-fit: cover;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: fixed;
  left:0;
  top: 0;
  bottom: 0;
  width: 50%;
  height: 65%;
}
.webpage{
  object-fit: cover;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 50%;
  z-index: 10;
  height: 100%;
  z-index: 1;
  background: #1c2020;
  
}
.overlay {
  position: absolute;
  height: 100vh;
  width: 100vw;
  background-color: rgba(00, 00, 00, 0.6);
  background-repeat: no-repeat;
  background-size:cover;
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
  z-index: 20;
  display: grid;
  place-items: center;
}
@media screen and (orientation:portrait) { 

  .overlay {
    background-image: url('../assets/background-portrait.jpg');
  }
}

@media screen and (orientation:landscape) { 
  .overlay {
    background-image: url('../assets/background-landscape.jpg');
  }
}

.footer {
  position: fixed;
  bottom: 0;
  margin: 0;
  width: 100%;
  padding: 20px;
}

.webpageLoading{
  animation: webpageAnimation 0.2s 1;
  transition: 2s ease-in-out;
  margin-top: 50%;
  transform: translate(50%, -50%);
  width: 100%;
  height: 100%;
  height: max-content ;
  background-color: #f6f6f6;  
  /* border-top: 2px solid blue; */
  z-index: 999;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  margin-left: 50%;

}

@-webkit-keyframes delay {
  0%, 40%, 100% {
    transform: scaleY(0.05);
    -webkit-transform: scaleY(0.05);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}
@keyframes delay {
  0%, 40%, 100% {
    transform: scaleY(0.05);
    -webkit-transform: scaleY(0.05);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}

.loader {
  margin: 0 auto;
  width: 60px;
  height: 70px;
  text-align: center;
  font-size: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
          transform: translateY(-50%) translateX(-50%);
  z-index: 500;
  opacity: 1;
} 
.loader > div {
  height: 100%;
  width: 8px;
  display: inline-block;
  float: left;
  margin-left: 2px;
  -webkit-animation: delay 0.8s infinite ease-in-out;
          animation: delay 0.8s infinite ease-in-out;
}
.loader .bar1 {
  background-color: #754fa0;
}
.loader .bar2 {
  background-color: #09b7bf;
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s;
}
.loader .bar3 {
  background-color: #90d36b;
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
}
.loader .bar4 {
  background-color: #f2d40d;
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}
.loader .bar5 {
  background-color: #fcb12b;
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
}
.loader .bar6 {
  background-color: #ed1b72;
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.iframeWebsite {
  background-color: white;
  transition: 1s ease-in-out;
  animation: iframeAnimation 1s 1;
}

@keyframes iframeAnimation {
  0%{
    margin-top: 50%;
    height: 0%;
    width: 0%;
  }  
  25%{
    height: 2%;
    width: 100%;
  }
  50%{
    width: 100%;
  }
  100%{
    margin-top: 0%;
    height: 100%;
    width: 100%;
  }
}

@keyframes webpageAnimation {
  0%{
    width: 100%;
  height: 100%;
}
  50% { 
  height: 1%;
  width: 100%;
}  
  100%{
    width: 0%;
  height: 0%;
}
}
.filter-white{
 filter: brightness(0) invert(1);
}

@media screen and (max-width: 1080px) {
  .video {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 50%;
    padding-bottom: 0;
  }
  .webpage {
    object-fit: cover;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: fixed;
    right: 0;
    top: 50%;
    bottom: 0;
    width: 100%;
    z-index: 10;
    height: 50%;
    z-index: 1;
  }
}